<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-sm-9" v-if="!!company">
        <label>شركة التأمين:</label>&nbsp;<label class="link">{{
          company.content
        }}</label>
      </div>
      <div class="col-sm-3">
        <BootstrapSelect
          id="pricing"
          :selected="selected"
          :options="routes"
          :search="false"
          :empty-option="false"
          @changed="setPricing"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "@/common/Storage";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: { BootstrapSelect },
  data() {
    return {
      company: "",
      formData: {},
      selected: "",
    };
  },
  provide() {
    return {
      formData: this.formData,
      company: this.companyId,
    };
  },
  computed: {
    routes() {
      return [
        { id: "winch.pricing", content: "أسعار الونشات" },
        { id: "fees.pricing", content: "عمولات المزاد" },
        { id: "invoices.list", content: "كشف المزادات" },
      ];
    },
    companyId() {
      return this.$route.params.company;
    },
    storageName() {
      return !!this.companyId ? `company-data-${this.companyId}` : "";
    },
  },
  methods: {
    clearStorage() {
      Storage.removeStartWith("company-data-");
    },
    setPricing(selected) {
      if (!this.companyId || !selected) return false;
      this.selected = selected;
      this.$router.replace({
        name: selected,
        params: { company: this.companyId },
      });
    },
    async setCompany() {
      if (!this.companyId) return false;
      const storedCompany = Storage.get(this.storageName);
      this.company = !!storedCompany ? storedCompany.data : "";
      if (!this.company) {
        const response = await http.send("company", {
          _method: "GET",
          company: this.companyId,
        });
        if (http.responseAccepted(response)) {
          this.clearStorage();
          this.company = response.data.company;
          Storage.set(this.storageName, this.company, 3); // commented
        }
      }
    },
  },
  async created() {
    this.formData.company = this.companyId;
    this.selected = this.$route.name;
    await this.setCompany();
  },
};
</script>

<style scoped>
.nav-link {
  border-radius: 0;
  margin: 0;
  background-color: #f06b78;
  color: white;
}

.active {
  background-color: white;
}
</style>
